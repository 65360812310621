<template>
  <el-row class="m-b-50 m-t-20 min-h-570">
    <div
      class="min-h-570"
      style="width: 1200px; margin: 40px auto; margin-left: 40%"
    >
      <Coupon :couponList="couponList" />
    </div>
  </el-row>
</template>

<script>
import Coupon from "@/components/pay/coupon";

export default {
  components: {
    Coupon,
  },
  data() {
    return {
      couponList: [], // 存放优惠券
      aid: "",
    };
  },
  created() {
    this.getCoupon();
  },
  methods: {
    getCoupon() {
      this.aid = this.$route.query.aid;
      if (this.aid == undefined) {
        let item = {
          amount: 9999,
          cid: 1,
          cname: "来晚啦,优惠券领完了~",
          couponState: 5,
          enable: 1,
          endTime: "2099.12.31",
          enddate: 1611727199000,
          id: 91,
          name: "暂无",
          receiveSum: 0,
          src: "http://www.xzjy365.com/personal/coponSrc?aid=91",
          startTime: "1970.01.01",
          startdate: 1624251600000,
          state: 1,
          total: 1,
          type: 2,
          userSum: 1,
        };
        this.couponList.push(item);
        return false;
      }
      let data = {
        uid: this.$f.uid(),
        aid: this.aid,
      };
      this.Api.Coupon.getCoupon(data).then((res) => {
        if (res.status == 200) {
          this.couponList.push(res.data);
        } else {
          let item = {
            amount: 9999,
            cid: 1,
            cname: "来晚啦,优惠券领完了~",
            couponState: 5,
            enable: 1,
            endTime: "2099.12.31",
            enddate: 1611727199000,
            id: 91,
            name: "暂无",
            receiveSum: 0,
            src: "http://www.xzjy365.com/personal/coponSrc?aid=91",
            startTime: "1970.01.01",
            startdate: 1624251600000,
            state: 1,
            total: 1,
            type: 2,
            userSum: 1,
          };
          this.couponList.push(item);
        }
      });
    },
  },
};
</script>

<style scoped>
</style>