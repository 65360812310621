import { render, staticRenderFns } from "./receiveCou.vue?vue&type=template&id=6629c477&scoped=true&"
import script from "./receiveCou.vue?vue&type=script&lang=js&"
export * from "./receiveCou.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6629c477",
  null
  
)

export default component.exports